import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledButton } from 'components/ui/button';

export const Section = styled.section`
  padding-top: ${(props) => (props.deleteMargin ? 0 : '3.5rem')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .load-more {
    margin: 80px auto 0;
    background-color: #fff;
    color: #0a4ed6;
    &:hover {
      background-color: #0a4ed6;
      color: #fff;
    }
    ${media.tablet} {
      margin: 72px auto 0;
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  ${media.desktop} {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 24px;
  }
`;

export const CustomButton = styled(StyledButton)``;
