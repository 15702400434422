import React from 'react';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import Layout from 'layout';
import ArticlePreview from 'components/blog/blogArticlePreview';
import { posts } from 'data/posts';
import NewsletterBox from 'components/blog/newsletterBox';
import AllArticles from 'components/blog/allArticles';
import StartUsing from 'components/ui/startUsing';
import CustomLink, { StyledLink } from 'components/ui/link';

export const StyledSection = styled.section`
  padding: 13rem 0 2rem;
  @media (max-width: 1440px) {
    padding: 8rem 0 2rem;
  }
  ${media.tablet} {
    padding: 120px 0 2rem;
  }
`;

export const StyledHeader = styled.div`
  margin-bottom: 92px;
  @media (max-width: 1440px) {
    margin-bottom: 64px;
  }
  ${media.tablet} {
    margin-bottom: 48px;
  }
`;

export const StyledBlogTitle = styled.h1`
  margin: 0 0 8px;
  &.line {
    span {
      &::after {
        background-color: #ffe478;
      }
    }
  }

  ${media.tablet} {
    font-size: 32px;
    line-height: 44px;
    margin: 0 0 6px;
  }
`;

export const StyledBlogDesc = styled.p`
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
  ${media.tablet} {
    font-size: 18px;
    line-height: 23px;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.desktop} {
    flex-direction: column;
  }
`;

const StyledList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  flex-basis: 47%;
  transform: translateY(-4%);
  @media (max-width: 1440px) {
    transform: translateY(0);
  }

  ${media.desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 33px;
    transform: translateY(0);
    margin-bottom: 0;
    margin-top: 40px;
  }
  ${media.tablet} {
    margin-top: 0;
  }
`;

const RecentPosts = styled.h2`
  margin: 0 0 20px;
  font-size: 36px;
  font-weight: 800;
  ${media.desktop} {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    margin: 0;
  }
  ${media.tablet} {
    font-size: 26px;
  }
`;

const StyledFirstPost = styled.ul`
  list-style: none;
  padding: 0;
  flex-basis: 46%;
  ${media.tablet} {
    margin-bottom: 64px;
  }
`;

export const GuestPostBox = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  padding: 0;
  ${media.tablet} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 0;
    grid-row-gap: 32px;
    align-items: flex-end;
  }
  ${media.mobile} {
    padding: 0 15px;
  }
  & .box {
    display: block;
    padding: 40px 48px;
    background-color: #f8f8f8;
    border-radius: 6px;
    cursor: pointer;
    height: fit-content;
    color: #000;
    height: 100%;
    &:hover {
      text-decoration: none;
      box-shadow: none;
      ${StyledLink} {
        text-decoration: underline;
        &::after {
          transform: translateX(8px);
        }
      }
    }
    ${media.tablet} {
      height: auto;
    }
    ${media.mobile} {
      padding: 24px 30px;
    }
    & h3 {
      font-size: 26px;
      line-height: 50px;
      font-weight: 800;
      margin-bottom: 24px;
      ${media.mobile} {
        margin-bottom: 16px;
        font-size: 22px;
        line-height: 32px;
      }
    }
    & p {
      margin: 0;
    }
    & p:first-of-type {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 32px;
      ${media.mobile} {
        margin-bottom: 24px;
        font-size: 14px;
        line-height: 24px;
      }
    }
    ${StyledLink} {
      font-weight: 700;
      font-size: 16px;
    }
  }
`;

const Blog = () => {
  const renderArticles = () => {
    if (!posts || posts.length === 0) return false;
    const mapArticles = (first) => {
      const blogPosts = posts
        .filter((post) => post.group !== 'updates')
        .map((el, idx) => (
          <li key={el.link || idx}>
            <ArticlePreview
              {...el}
              author={{
                authorName: el.author.authorName,
                avatar: el.author.avatar,
              }}
              timeToRead={el.timeToRead}
              list={el.group}
              first={first || null}
            />
          </li>
        ));
      return blogPosts;
    };

    return (
      <StyledSection>
        <div className="container">
          <StyledHeader>
            <StyledBlogTitle className="line">
              LiveSession <span>blog</span>
            </StyledBlogTitle>
            <StyledBlogDesc>UX, conversion, analytics and more</StyledBlogDesc>
          </StyledHeader>
          <Box>
            <StyledFirstPost>{mapArticles(true).slice(0, 1)}</StyledFirstPost>
            <StyledList>
              <RecentPosts>Recent posts</RecentPosts>
              {mapArticles().slice(1, 5)}
            </StyledList>
          </Box>
        </div>
      </StyledSection>
    );
  };

  return (
    <Layout
      metaTitle="LiveSession Blog | Session Replays, Analytics, UX and CRO"
      canonical="/blog/"
      metaDescription="All about session replays and qualitative analytics. Read tips and best practices from UX and CRO experts. Grow your business with the LiveSession blog."
    >
      {renderArticles()}
      <NewsletterBox />
      <AllArticles />
      <GuestPostBox className="container">
        <a href="/blog/guest-post/" className="box">
          <h3>Want to contribute?</h3>
          <p>We accept guest post submissions!</p>
          <CustomLink tag="p" withArrow>
            Find out more
          </CustomLink>
        </a>
        <a href="/customers/rocketlink/" className="box">
          <h3>110% increase in signups?</h3>
          <p>Read how RocketLink grew with LiveSession</p>
          <CustomLink tag="p" withArrow>
            Learn more
          </CustomLink>
        </a>
      </GuestPostBox>
      <StartUsing />
    </Layout>
  );
};

export default Blog;
