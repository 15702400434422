import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { posts } from 'data/posts';
import ArticlePreview from 'components/blog/blogArticlePreview';
import Button from 'components/ui/button';
import * as Styled from './styled';

const AllArticles = ({ updates, deleteMargin }) => {
  const listenResize = () => {
    if (typeof window !== 'undefined' && window.innerWidth < 992) {
      return updates ? 8 : 13;
    }
    return updates ? 9 : 14;
  };
  const [countOfPosts, loadMore] = useState(listenResize());

  const renderRestOfArticles = () => {
    const blogPosts = posts.map((el, idx) => (
      <ArticlePreview key={el.link || idx} {...el} isList />
    ));
    if (updates) {
      return blogPosts.filter((post) => post.props.group === 'updates');
    }
    return blogPosts.filter((post) => post.props.group !== 'updates');
  };

  return (
    <Styled.Section className="container" deleteMargin={deleteMargin}>
      <Styled.Grid>{renderRestOfArticles().slice(updates ? 0 : 5, countOfPosts)}</Styled.Grid>
      <Button
        onClick={() => loadMore(countOfPosts + 6)}
        as="button"
        disabled={countOfPosts >= renderRestOfArticles().length}
        style={{
          opacity: countOfPosts >= renderRestOfArticles().length && '0.5',
        }}
        className="load-more"
        center
      >
        Load more posts
      </Button>
    </Styled.Section>
  );
};

export default AllArticles;

AllArticles.propTypes = {
  updates: PropTypes.bool,
  deleteMargin: PropTypes.bool,
};

AllArticles.defaultProps = {
  updates: false,
  deleteMargin: false,
};
