import React from 'react';
import Layout from 'layout';
import styled from 'styled-components';
import AllArticles from 'components/blog/allArticles';
import { StyledHeader, StyledBlogTitle, StyledBlogDesc, StyledSection } from './blog';

const ExtendStyledSection = styled(StyledSection)`
  padding-left: 0;
  padding-right: 0;
`;

const Updates = () => {
  return (
    <Layout
      metaTitle="LiveSession Product Updates | Session Replay News"
      metaDescription="See what’s new in LiveSession. Read the latest product updates and stay up to date."
      canonical="/updates/"
      className="blog"
    >
      <ExtendStyledSection>
        <div className="container">
          <StyledHeader>
            <StyledBlogTitle className="line">
              LiveSession <span>updates</span>
            </StyledBlogTitle>
            <StyledBlogDesc>What&apos;s new in LiveSession?</StyledBlogDesc>
          </StyledHeader>
        </div>
        <AllArticles updates deleteMargin />
      </ExtendStyledSection>
    </Layout>
  );
};

export default Updates;
